import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import apiConfig from "config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  // Function to handle token refresh
  const refreshToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiConfig.baseURL}/api/auth/refresh`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.access_token);
        return data.access_token;
      } else {
        // If token refresh fails, log out the user
        toast.error("Session expired. Please log in again.");
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      toast.error("An error occurred. Please log in again.");
      localStorage.removeItem("token");
      navigate("/login");
    }
  };

  // Function to handle authenticated requests
  const fetchWithAuth = async (url, options = {}) => {
    let token = localStorage.getItem("token");

    if (!options.headers) {
      options.headers = {};
    }

    options.headers["Authorization"] = `Bearer ${token}`;

    const response = await fetch(url, options);

    if (response.status === 401) {
      // Refresh the token and retry the request
      token = await refreshToken();
      if (token) {
        options.headers["Authorization"] = `Bearer ${token}`;
        return await fetch(url, options);
      }
    }

    return response;
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${apiConfig.baseURL}/api/auth/check-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.detail || "User does not exist");
        return;
      }

      const data = await response.json();
      if (data.password_set) {
        setStep("password");
      } else {
        setStep("registration");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiConfig.baseURL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.detail || "Invalid credentials");
        return;
      }

      const { access_token } = await response.json();
      localStorage.setItem("token", access_token);
      toast.success("Login successful!");
      navigate("/main/index");
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !password || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        `${apiConfig.baseURL}/api/auth/complete-registration/${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            password,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.detail || "Registration failed");
        return;
      }

      toast.success("Registration successful! Please log in.");
      setStep("password");
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const getFormSubmitHandler = () => {
    switch (step) {
      case "email":
        return handleEmailSubmit;
      case "password":
        return handleLogin;
      case "registration":
        return handleRegistration;
      default:
        return (e) => e.preventDefault();
    }
  };

  return (
    <Container className="align-items-center justify-content-center vh-100">
      <ToastContainer />
      <Row className="justify-content-center">
        <div className="text-center">
          <img
            src={require("../../assets/img/brand/logo.png")}
            alt="Logo"
            style={{ width: "300px", marginBottom: "50px" }}
          />
        </div>
      </Row>

      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-3">
                {step === "email" && <p>Enter your email to continue</p>}
                {step === "password" && <p>Enter your password</p>}
                {step === "registration" && (
                  <span>Complete your registration</span>
                )}
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <form onSubmit={getFormSubmitHandler()}>
                {step === "email" && (
                  <>
                    <FormGroup className="mb-3">
                      <Label for="email">Email</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="email"
                          placeholder="Email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          autoFocus
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Next
                      </Button>
                    </div>
                  </>
                )}

                {step === "password" && (
                  <>
                    <FormGroup className="mb-3">
                      <Label for="password">Password</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="password"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          autoFocus
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Sign in
                      </Button>
                    </div>
                  </>
                )}

                {step === "registration" && (
                  <>
                    <FormGroup className="mb-3">
                      <Label for="firstName">First Name</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="firstName"
                          placeholder="First Name"
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          autoFocus
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="lastName">Last Name</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="lastName"
                          placeholder="Last Name"
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="regPassword">Password</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="regPassword"
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="confirmPassword">Confirm Password</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Register
                      </Button>
                    </div>
                  </>
                )}
              </form>
            </CardBody>
          </Card>
          {/* {step !== "email" && (
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#forgot"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
            </Row>
          )} */}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
