import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import "./Header.css";
import apiConfig from "config";

const MainHeader = ({ showCards = true }) => {
  const [data, setData] = useState({
    submissionsToday: { count: null, percentage_change: null },
    submissionsThisMonth: { count: null, percentage_change: null },
    underwritersToday: { count: null, percentage_change: null },
    underwritersThisMonth: { count: null, percentage_change: null },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const [submissionResponse, underwritersResponse] = await Promise.all([
        fetch(
          `${apiConfig.baseURL}/api/dashboard-statistics/submission-trends`,
          { headers }
        ),
        fetch(
          `${apiConfig.baseURL}/api/dashboard-statistics/active-underwriters`,
          { headers }
        ),
      ]);

      if (!submissionResponse.ok || !underwritersResponse.ok) {
        throw new Error("Failed to fetch data");
      }

      const submissionData = await submissionResponse.json();
      const underwritersData = await underwritersResponse.json();

      setData({
        submissionsToday: submissionData.today,
        submissionsThisMonth: submissionData.this_month,
        underwritersToday: underwritersData.today,
        underwritersThisMonth: underwritersData.this_month,
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      toast.error("Error fetching data: " + err.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to render each card with safe data handling
  const renderCard = (title, iconClass, bgColor, dataObj, sinceText) => (
    <Col lg="6" xl="3" key={title}>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                {title}
              </CardTitle>
            </div>
            <Col className="col-auto">
              <div
                className={`icon-container ${bgColor} text-white rounded-circle shadow`}
              >
                <i className={iconClass} />
              </div>
            </Col>
          </Row>
          <div className="stat-content">
            <span className="h2 font-weight-bold mb-0 widget-numbers">
              {dataObj && dataObj.count !== null
                ? dataObj.count
                : loading
                ? "Loading..."
                : "N/A"}
            </span>
            <p className="mt-3 mb-0 text-muted text-sm">
              <span
                className={`mr-2 ${
                  dataObj && dataObj.percentage_change > 0
                    ? "text-success"
                    : dataObj && dataObj.percentage_change < 0
                    ? "text-danger"
                    : "text-muted"
                }`}
              >
                <i
                  className={`fas fa-arrow-${
                    dataObj && dataObj.percentage_change > 0
                      ? "up"
                      : dataObj && dataObj.percentage_change < 0
                      ? "down"
                      : "right"
                  }`}
                />{" "}
                {dataObj && dataObj.percentage_change !== null
                  ? dataObj.percentage_change.toFixed(2)
                  : "N/A"}
                %
              </span>{" "}
              <span className="text-nowrap">{sinceText}</span>
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {showCards && (
              <Row>
                {/* Submissions Today */}
                {renderCard(
                  "Submissions Today",
                  "fas fa-file-alt",
                  "bg-danger",
                  data.submissionsToday,
                  "Since yesterday"
                )}
                {/* Submissions This Month */}
                {renderCard(
                  "Submissions this month",
                  "fas fa-calendar-alt",
                  "bg-warning",
                  data.submissionsThisMonth,
                  "Since last month"
                )}
                {/* Underwriters Today */}
                {renderCard(
                  "Underwriters active today",
                  "fas fa-user-check",
                  "bg-yellow",
                  data.underwritersToday,
                  "Since yesterday"
                )}
                {/* Underwriters This Month */}
                {renderCard(
                  "Underwriters active this month",
                  "fas fa-calendar-check",
                  "bg-info",
                  data.underwritersThisMonth,
                  "Since last month"
                )}
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default MainHeader;
