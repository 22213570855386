import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";

// core components
import MainNavbar from "components/Navbars/Navbar.js";
import MainFooter from "components/Footers/Footer.js";
import MainSidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import SubmissionDetails from "pages/submission-details-page/submission-details-page";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/main") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    // Navigate to login page, which is now outside MainLayout
    window.location.href = "/login";
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} />
      <MainSidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/main/index",
          imgSrc: require("../assets/img/brand/logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <MainNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
          handleLogout={handleLogout}
        />
        <Routes>
          {getRoutes(routes)}
          <Route
            path="submission/:submissionId"
            element={<SubmissionDetails />}
          />
          <Route path="*" element={<Navigate to="/main/index" replace />} />
        </Routes>
        <Container fluid>
          <MainFooter />
        </Container>
      </div>
    </>
  );
};

export default MainLayout;
