import Index from "pages/dashboard-page/dashboard-page.js";
import SubmissionsList from "pages/submissions-list-page/submissions-list-page.js";
import NewSubmissionPage from "pages/new-submission-page/new-submission-page.js";
import SubmissionDetails from "pages/submission-details-page/submission-details-page";
import LoginPage from "pages/login-register-page/Login";

var routes = [
  {
    path: "/index",
    name: "Home",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/main",
    sidebarDisplay: true, // Display in sidebar
  },
  {
    path: "/newsubmission",
    name: "New Submission",
    icon: "fa-solid fa-file-circle-plus text-blue",
    component: <NewSubmissionPage />,
    layout: "/main",
    sidebarDisplay: true,
  },
  {
    path: "/submissions",
    name: "All Submissions",
    icon: "fa-solid fa-list text-orange",
    component: <SubmissionsList />,
    layout: "/main",
    sidebarDisplay: true,
  },
  {
    path: "/statistics",
    name: "Statistics",
    icon: "fa-solid fa-chart-pie text-red",
    component: <SubmissionsList />,
    layout: "/main",
    sidebarDisplay: false,
  },
  {
    path: "/submission/:submissionId", // This route should not appear in the sidebar
    component: <SubmissionDetails />, // Define the component without a name or icon
    layout: "/main",
    sidebarDisplay: false, // Set to false or omit this property to hide in sidebar
  },
];

export default routes;
