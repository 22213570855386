import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import "./submission-details-page.css";
import apiConfig from "config";
import ExtendedHeader from "components/Headers/ExtendedHeader.js";
import PerilDetails from "./components/peril-details";
import ClaimsDetails from "./components/claim-details";
import SourceModal from "./components/source-modal";

const SubmissionDetails = () => {
  const { submissionId } = useParams();
  const [submissionData, setSubmissionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileHierarchy, setFileHierarchy] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [streetViewAvailable, setStreetViewAvailable] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const navigate = useNavigate(); // UseNavigate hook for navigation
  const toggleModal = () => setModalOpen(!modalOpen);

  const openModalWithSources = (sources) => {
    setSelectedSources(sources);
    toggleModal();
  };

  const buildFileHierarchy = (files) => {
    if (!files || files.length === 0) return [];

    const fileMap = {};
    files.forEach((file) => {
      fileMap[file.metadata.source] = {
        title: file.metadata.source,
        key: file.metadata.source,
        type: file.metadata.document_type,
        children: [],
      };
    });

    const roots = [];
    files.forEach((file) => {
      const node = fileMap[file.metadata.source];
      if (file.metadata.parent_source) {
        if (fileMap[file.metadata.parent_source]) {
          fileMap[file.metadata.parent_source].children.push(node);
        }
      } else {
        roots.push(node);
      }
    });

    return roots;
  };

  const fetchCoordinates = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${apiConfig.googleMapsApiKey}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch coordinates");
      }
      const data = await response.json();
      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({ lat, lng });
      } else {
        setCoordinates(null);
        throw new Error("Geocoding API error: " + data.status);
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setCoordinates(null);
      toast.error(`Error fetching coordinates: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchSubmissionDetails = async () => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await fetch(
          `${apiConfig.baseURL}/api/submission-details/submission/${submissionId}`,
          { headers }
        );
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
          return;
        } else if (!response.ok) {
          throw new Error("Failed to fetch submission details.");
        }
        const data = await response.json();
        setSubmissionData(data);

        if (data.submission_documents && data.submission_documents.length > 0) {
          const treeData = buildFileHierarchy(data.submission_documents);
          setFileHierarchy(treeData);
        }

        // Fetch coordinates based on the property address
        if (data.property_address) {
          await fetchCoordinates(data.property_address);
        }
      } catch (error) {
        toast.error(`Error fetching submission details: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (submissionId) {
      fetchSubmissionDetails();
    }
  }, [submissionId]);

  // Load the Google Maps JavaScript API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiConfig.googleMapsApiKey,
  });

  // Check if Street View is available
  useEffect(() => {
    if (isLoaded && coordinates) {
      const streetViewService = new window.google.maps.StreetViewService();
      streetViewService.getPanorama(
        { location: coordinates, radius: 50 },
        (data, status) => {
          if (status === "OK") {
            setStreetViewAvailable(true);
          } else {
            setStreetViewAvailable(false);
          }
        }
      );
    }
  }, [isLoaded, coordinates]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner type="grow" color="primary" />
        <p>Loading submission details...</p>
      </div>
    );
  }

  if (!submissionData) {
    return <p>Submission not found</p>;
  }

  const getFileIcon = (type) => {
    switch (type) {
      case "email_body":
        return <i className="bi bi-envelope-fill text-primary file-icon"></i>;
      case "email_attachment":
        return <i className="bi bi-paperclip file-icon"></i>;
      case "pdf":
        return <i className="bi bi-filetype-pdf text-danger file-icon"></i>;
      default:
        return <i className="bi bi-file-earmark-fill file-icon"></i>;
    }
  };

  const FileTree = ({ nodes }) => {
    return (
      <ListGroup className="file-tree">
        {nodes.map((node) => (
          <TreeNode key={node.key} node={node} />
        ))}
      </ListGroup>
    );
  };

  const TreeNode = ({ node }) => {
    const [isOpen, setIsOpen] = useState(true);
    const hasChildren = node.children && node.children.length > 0;

    const toggle = () => setIsOpen(!isOpen);

    return (
      <>
        <ListGroupItem
          className={`d-flex align-items-center ${
            hasChildren ? "folder-node" : "file-node"
          }`}
          onClick={hasChildren ? toggle : null}
        >
          {getFileIcon(hasChildren ? "folder" : node.type)}
          <span className="ms-2">{node.title}</span>
        </ListGroupItem>
        {hasChildren && (
          <Collapse isOpen={isOpen}>
            <FileTree nodes={node.children} />
          </Collapse>
        )}
      </>
    );
  };

  const handleExportToExcel = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-processing/download-excel-report/${submissionId}`,
        { headers }
      );

      if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
        return;
      } else if (!response.ok) {
        throw new Error("Failed to download Excel report.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `report_${submissionId}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.success("Excel report downloaded successfully!");
    } catch (error) {
      toast.error(`Error downloading Excel report: ${error.message}`);
    }
  };

  const perils = submissionData.perils_covered.map((peril) => ({
    ...peril,
    peril_details: submissionData.perils_details[peril.peril_name],
  }));

  // Custom StreetView component
  const StreetView = ({ position }) => {
    const streetViewRef = useRef(null);

    useEffect(() => {
      if (isLoaded && streetViewRef.current) {
        new window.google.maps.StreetViewPanorama(streetViewRef.current, {
          position: position,
          pov: { heading: 100, pitch: 0 },
          addressControl: false,
          fullscreenControl: false,
          linksControl: false,
          panControl: false,
          enableCloseButton: false,
        });
      }
    }, [isLoaded, position]);

    if (!isLoaded) {
      return <p>Loading Street View...</p>;
    }

    return (
      <div ref={streetViewRef} style={{ height: "600px", width: "100%" }} />
    );
  };

  return (
    <>
      <ExtendedHeader
        insuredName={submissionData.insured_name}
        insuredAddress={submissionData.property_address}
        submissionStatus={submissionData.status}
        PD={submissionData.pd}
        BI={submissionData.bi}
        TIV={submissionData.tiv}
        PD_sources={submissionData.pd_sources}
        BI_sources={submissionData.bi_sources}
        TIV_sources={submissionData.tiv_sources}
        openModalWithSources={openModalWithSources}
      />
      <Container className="mt--7" fluid>
        {/* Profile Section */}
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
            {/* Left Column */}
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/theme/submission-logo.png")}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between"></div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">
                          {submissionData.completed_fields}
                        </span>
                        <span className="description">Completed Fields</span>
                      </div>
                      <div>
                        <span className="heading">
                          {submissionData.total_fields}
                        </span>
                        <span className="description">Total Fields</span>
                      </div>
                      <div>
                        <span className="heading">{submissionData.status}</span>
                        <span className="description">Status</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{submissionData.insured_name}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {submissionData.property_address}
                  </div>
                  <hr className="my-4" />
                  <p>{submissionData.insured_summary}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="6">
            {/* Right Column */}
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Submission Files</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="d-flex flex-column justify-content-between">
                <FileTree nodes={fileHierarchy} />
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={handleExportToExcel}
                  >
                    Export Extractions to Excel
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Section Divider for Perils Information */}
        <div className="section-divider">
          <div className="icon-wrap">
            <i className="bi bi-bar-chart-line-fill"></i>
          </div>
          <h2>Perils Information</h2>
        </div>

        {/* Perils Information Section */}
        <PerilDetails
          perils={perils}
          country_name={submissionData.property_country}
          country_code={submissionData.property_country_code}
          broker_name={submissionData.broker_name}
          broker_company={submissionData.broker_company}
          country_name_sources={submissionData.property_country_sources}
          broker_name_sources={submissionData.broker_name_sources}
          openModalWithSources={openModalWithSources}
        />

        {/* Section Divider for Additional Property Information */}
        <div className="section-divider">
          <div className="icon-wrap">
            <i className="bi bi-house-door-fill"></i>
          </div>
          <h2>Additional Property Information</h2>
        </div>

        {/* Additional Property Information Section */}
        <Row>
          {/* Construction Details */}
          <Col xl="3">
            <Card className="shadow-sm rounded">
              <CardHeader className="bg-success text-white d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Construction Details</h3>
                <i
                  className="bi bi-info-circle-fill text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(
                      submissionData.construction_details_sources
                    )
                  }
                  title="View Sources"
                />
              </CardHeader>
              <CardBody>
                {submissionData.construction_details?.length > 0 ? (
                  <ul className="list-unstyled">
                    {submissionData.construction_details.map(
                      (detail, index) => (
                        <li key={index}>
                          <i className="bi bi-house-door-fill text-warning"></i>
                          <span className="ms-2">&nbsp;{detail}</span>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <p>Construction details not available</p>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* Occupancy Details */}
          <Col xl="3">
            <Card className="shadow-sm rounded">
              <CardHeader className="bg-success text-white d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Occupancy Details</h3>
                <i
                  className="bi bi-info-circle-fill text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(
                      submissionData.occupancy_details_sources
                    )
                  }
                  title="View Sources"
                />
              </CardHeader>
              <CardBody>
                {submissionData.occupancy_details?.length > 0 ? (
                  <ul className="list-unstyled">
                    {submissionData.occupancy_details.map((detail, index) => (
                      <li key={index}>
                        <i className="bi bi-building-fill text-primary"></i>
                        <span className="ms-2">&nbsp;{detail}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Occupancy details not available</p>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* Fire Protections */}
          <Col xl="3">
            <Card className="shadow-sm rounded">
              <CardHeader className="bg-success text-white d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Fire Protections</h3>
                <i
                  className="bi bi-info-circle-fill text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(
                      submissionData.fire_protections_sources
                    )
                  }
                  title="View Sources"
                />
              </CardHeader>
              <CardBody>
                {submissionData.fire_protections?.length > 0 ? (
                  <ul className="list-unstyled">
                    {submissionData.fire_protections.map((detail, index) => (
                      <li key={index}>
                        <i className="bi bi-fire text-danger"></i>
                        <span className="ms-2">&nbsp;{detail}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Fire protections not available</p>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* Security Details */}
          <Col xl="3">
            <Card className="shadow-sm rounded">
              <CardHeader className="bg-success text-white d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Security Details</h3>
                <i
                  className="bi bi-info-circle-fill text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(
                      submissionData.security_details_sources
                    )
                  }
                  title="View Sources"
                />
              </CardHeader>
              <CardBody>
                {submissionData.security_details?.length > 0 ? (
                  <ul className="list-unstyled">
                    {submissionData.security_details.map((detail, index) => (
                      <li key={index}>
                        <i className="bi bi-shield-lock-fill text-info"></i>
                        <span className="ms-2">&nbsp;{detail}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Security details not available</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal for showing sources */}
        <SourceModal
          isOpen={modalOpen}
          toggle={toggleModal}
          sources={selectedSources}
          submissionId={submissionId}
        />

        {/* Section Divider for Claims Information */}
        <div className="section-divider">
          <div className="icon-wrap">
            <i className="bi bi-file-earmark-text-fill"></i>
          </div>
          <h2>Claims Information</h2>
        </div>

        {/* Claims Information Section */}
        {submissionData.claims && submissionData.claims.length > 0 ? (
          <ClaimsDetails
            claims={submissionData.claims}
            claims_sources={submissionData.claims_sources}
            openModalWithSources={openModalWithSources}
          />
        ) : (
          <p>No claims available</p>
        )}

        {/* Conditionally render Map and Street View Sections */}
        {coordinates ? (
          isLoaded ? (
            <>
              {/* Section Divider for Location Map */}
              <div className="section-divider">
                <div className="icon-wrap">
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <h2>Location Map</h2>
              </div>

              {/* Map Section */}
              <Card className="shadow-sm rounded mb-4">
                <CardBody>
                  <GoogleMap
                    mapContainerStyle={{ height: "600px", width: "100%" }}
                    center={coordinates}
                    zoom={15}
                  >
                    <Marker position={coordinates} />
                  </GoogleMap>
                </CardBody>
              </Card>

              {/* Conditionally render Street View if available */}
              {streetViewAvailable === true && (
                <>
                  {/* Section Divider for Street View */}
                  <div className="section-divider">
                    <div className="icon-wrap">
                      <i className="bi bi-building"></i>
                    </div>
                    <h2>Street View</h2>
                  </div>

                  {/* Street View Section */}
                  <Card className="shadow-sm rounded mb-4">
                    <CardBody>
                      <StreetView position={coordinates} />
                    </CardBody>
                  </Card>
                </>
              )}

              {/* Show message if Street View is not available */}
              {streetViewAvailable === false && (
                <p>Street View not available for this location.</p>
              )}
            </>
          ) : (
            <div className="text-center mt-5">
              <Spinner type="grow" color="primary" />
              <p>Loading Maps...</p>
            </div>
          )
        ) : (
          <p>Map and Street View are not available</p>
        )}
      </Container>
    </>
  );
};

export default SubmissionDetails;
