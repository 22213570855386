import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

const ExtendedHeader = ({
  insuredName,
  insuredAddress,
  submissionStatus,
  PD,
  BI,
  TIV,
  PD_sources,
  BI_sources,
  TIV_sources,
  openModalWithSources, // <-- Add this prop to open the sources modal
}) => {
  /**
   * Renders a highlight card for PD, BI, or TIV
   * @param {string} title - The card title
   * @param {string | number} value - The displayed numeric or string value
   * @param {string} icon - The Font Awesome icon class
   * @param {string} color - The bootstrap color class (e.g. "primary", "success")
   * @param {array} sources - The array of source objects to show in the modal
   */
  const renderHighlightCard = (title, value, icon, color, sources = []) => (
    <Card className="mb-2" style={{ padding: "10px" }}>
      <CardBody
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "10px" }}
      >
        <div>
          <CardHeader
            className="text-uppercase card-title mb-0"
            style={{
              fontSize: "12px",
              paddingBottom: "5px",
              paddingTop: "0px",
            }}
          >
            {title}
          </CardHeader>
          <h5 className="mb-0" style={{ fontSize: "16px" }}>
            {value}
          </h5>
        </div>

        {/* Existing Icon -- now clickable to open the sources modal */}
        <div
          className={`icon icon-shape text-white rounded-circle bg-${color}`}
          style={{
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer", // <-- Make it clickable
          }}
          onClick={() => openModalWithSources(sources)} // <-- call the modal
          title="View Sources"
        >
          <i className={icon} style={{ fontSize: "18px" }} />
        </div>
      </CardBody>
    </Card>
  );

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage:
            "url(" + require("../../assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />

        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row className="w-100">
            {/* Existing content: Insured name, address, and status */}
            <Col lg="8" md="12">
              <h1 className="display-2 text-white">{insuredName}</h1>
              <p className="text-white mt-0 mb-5">{insuredAddress}</p>
              <Button color="info" onClick={(e) => e.preventDefault()}>
                {submissionStatus}
              </Button>
            </Col>

            {/* Highlight boxes for PD, BI, TIV with clickable icons */}
            <Col
              lg="4"
              md="12"
              className="d-flex flex-column justify-content-center"
            >
              {renderHighlightCard(
                "Property Damage (PD)",
                PD,
                "fas fa-home",
                "primary",
                PD_sources
              )}
              {renderHighlightCard(
                "Business Interruption (BI)",
                BI,
                "fas fa-business-time",
                "warning",
                BI_sources
              )}
              {renderHighlightCard(
                "Total Insured Value (TIV)",
                TIV,
                "fas fa-coins",
                "success",
                TIV_sources
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ExtendedHeader;
