import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "chart.js";
import { Line, Pie, Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.js";
import Header from "components/Headers/Header.js";
import apiConfig from "config";

const Index = () => {
  const [activeNav, setActiveNav] = useState(1);
  const [submissionsData, setSubmissionsData] = useState(null);
  const [propertyTypeData, setPropertyTypeData] = useState(null);
  const [underwriterData, setUnderwriterData] = useState(null);
  const [submissionSizeData, setSubmissionSizeData] = useState(null);
  const [geographicData, setGeographicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Helper function to handle requests and check for 401
  const fetchWithAuth = async (url) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, { headers });

    if (response.status === 401) {
      // Token expired or invalid, log out user and redirect
      localStorage.removeItem("token");
      navigate("/login");
      return;
    }

    return response.json();
  };

  // Fetch all required data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          submissionsRes,
          propertyTypeRes,
          underwriterRes,
          sizeRes,
          geoRes,
        ] = await Promise.all([
          fetchWithAuth(
            `${apiConfig.baseURL}/api/dashboard-statistics/submissions-over-time`
          ),
          fetchWithAuth(
            `${apiConfig.baseURL}/api/dashboard-statistics/submission-frequency-by-property-type`
          ),
          fetchWithAuth(
            `${apiConfig.baseURL}/api/dashboard-statistics/underwriter-performance`
          ),
          fetchWithAuth(
            `${apiConfig.baseURL}/api/dashboard-statistics/submission-size`
          ),
          fetchWithAuth(
            `${apiConfig.baseURL}/api/dashboard-statistics/geographic-distribution`
          ),
        ]);

        // Submissions Over Time (Line Chart)
        setSubmissionsData({
          labels: submissionsRes.map((entry) => entry.date),
          datasets: [
            {
              label: "Submissions",
              data: submissionsRes.map((entry) => entry.count),
              fill: true,
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, "rgba(78, 115, 223, 0.5)");
                gradient.addColorStop(1, "rgba(78, 115, 223, 0.0)");
                return gradient;
              },
              borderColor: "#4e73df",
              borderWidth: 2,
              tension: 0.4,
            },
          ],
        });

        // Submission Frequency by Property Type (Pie Chart)
        setPropertyTypeData({
          labels: propertyTypeRes.map((entry) => entry._id),
          datasets: [
            {
              data: propertyTypeRes.map((entry) => entry.count),
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4e73df"],
            },
          ],
        });

        // Underwriter Performance (Doughnut Chart)
        setUnderwriterData({
          labels: underwriterRes.map((entry) => entry._id),
          datasets: [
            {
              data: underwriterRes.map((entry) => entry.count),
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4e73df"],
              hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4e73df",
              ],
              borderWidth: 2,
              cutout: "70%", // Doughnut style
            },
          ],
        });

        // Submission Size (Bar Chart)
        setSubmissionSizeData({
          labels: ["Last 30 Days", "Last 7 Days", "Today"],
          datasets: [
            {
              label: "Files Processed",
              data: [sizeRes.last_30_days, sizeRes.last_7_days, sizeRes.today],
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, "rgba(54, 162, 235, 0.7)");
                gradient.addColorStop(1, "rgba(54, 162, 235, 0.0)");
                return gradient;
              },
              borderRadius: 10, // Rounded bars
            },
          ],
        });

        // Geographic Distribution of Submissions (Horizontal Bar Chart)
        setGeographicData({
          labels: geoRes.map((entry) => entry._id),
          datasets: [
            {
              label: "Submissions by Country",
              data: geoRes.map((entry) => entry.count),
              backgroundColor: "#4e73df",
              barPercentage: 0.5,
              categoryPercentage: 0.5,
            },
          ],
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-dark ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-dark mb-0">Submissions Over Time</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="chart">
                    <Line
                      data={submissionsData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-dark mb-0">
                      Submission Frequency by Property Type
                    </h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="chart">
                    <Pie
                      data={propertyTypeData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xl="4">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-dark mb-0">Underwriter Performance</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="chart">
                    <Pie
                      data={underwriterData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-dark mb-0">
                      Submission Size (Files Processed)
                    </h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="chart">
                    <Bar
                      data={submissionSizeData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-dark mb-0">
                      Geographic Distribution of Submissions
                    </h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div className="chart">
                    <Bar
                      data={geographicData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        indexAxis: "y", // Horizontal bar chart
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
