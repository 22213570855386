import React from "react";
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";
import "./../submission-details-page.css";

const ClaimsDetails = ({ claims, claims_sources, openModalWithSources }) => {
  const renderClaimCard = (claim, index) => {
    return (
      <Col xl="4" key={index} className="mb-4">
        <Card className="modern-claim-panel h-100">
          <CardBody className="d-flex flex-column align-items-start">
            {/* Header section with title and icon */}
            <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
              <CardHeader className="text-uppercase card-title mb-0">
                Claim #{index + 1}
              </CardHeader>

              {/* The existing icon, now clickable to open sources */}
              <div
                className="icon icon-shape text-white rounded-circle modern-icon bg-warning"
                style={{ cursor: "pointer" }}
                onClick={() => openModalWithSources(claims_sources || [])}
                title="View Sources"
              >
                <i className="bi bi-file-earmark-text-fill" />
              </div>
            </div>

            {/* Claim details section */}
            <div className="modern-text flex-grow-1">
              <ul className="list-unstyled">
                {/* Reason for the claim */}
                <li className="mb-2">
                  <strong>Reason: </strong>
                  <span className="text-muted">{claim.reason}</span>
                </li>
              </ul>
            </div>

            {/* Buttons for Date and Amount at the bottom */}
            <div className="d-flex justify-content-between w-100 mt-auto pt-3">
              {/* Date as a button */}
              <Button color="info" outline className="text-dark date-button">
                Date: {claim.date}
              </Button>
              {/* Amount as a button */}
              <Button color="success" outline className="amount-button">
                Amount: ${claim.amount.toLocaleString()}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <div className="mt-5">
      <Row>
        {/* Iterate through the claims and render each claim card */}
        {claims.map((claim, index) => renderClaimCard(claim, index))}
      </Row>
    </div>
  );
};

export default ClaimsDetails;
