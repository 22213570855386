import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "components/Headers/Header.js";
import apiConfig from "config";

const SubmissionsList = () => {
  // Core state variables
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // configurable page size
  const [totalCount, setTotalCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [underwriters, setUnderwriters] = useState([]);
  const [statuses, setStatuses] = useState({});
  const navigate = useNavigate();

  // New state for filtering and sorting
  const [filterUnderwritingStatus, setFilterUnderwritingStatus] = useState("");
  const [filterUnderwriter, setFilterUnderwriter] = useState("");
  const [filterInsuredName, setFilterInsuredName] = useState("");
  const [filterBrokerName, setFilterBrokerName] = useState("");
  const [sortBy, setSortBy] = useState("created");
  const [sortOrder, setSortOrder] = useState(-1); // -1: descending, 1: ascending

  // New state for showing/hiding the filter panel
  const [filtersVisible, setFiltersVisible] = useState(false);

  // Helper: Returns a subtle indicator color based on underwriting_status.
  const getIndicatorColor = (submission) => {
    switch (submission.underwriting_status) {
      case "QUOTE":
        return "rgba(40, 167, 69, 0.3)";
      case "DECLINE":
        return "rgba(220, 53, 69, 0.3)";
      case "FURTHER_INFO":
      case "AWAITING_MODELING":
      case "NOT_RESPONDED":
        return "rgba(255, 193, 7, 0.3)";
      default:
        return "transparent";
    }
  };

  // Fetch submissions with filtering, sorting, and pagination
  useEffect(() => {
    const fetchSubmissions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        // Build query parameters
        const params = new URLSearchParams();
        params.append("page", page);
        params.append("page_size", pageSize);
        if (filterUnderwritingStatus)
          params.append("underwriting_status", filterUnderwritingStatus);
        if (filterUnderwriter) params.append("underwriter", filterUnderwriter);
        if (filterInsuredName) params.append("insured_name", filterInsuredName);
        if (filterBrokerName) params.append("broker_name", filterBrokerName);
        if (sortBy) params.append("sort_by", sortBy);
        params.append("sort_order", sortOrder);

        const response = await fetch(
          `${
            apiConfig.baseURL
          }/api/submission-details/submissions?${params.toString()}`,
          { headers }
        );
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
          return;
        } else if (!response.ok) {
          throw new Error(`Error fetching submissions: ${response.statusText}`);
        }
        const data = await response.json();
        setSubmissions(data.submissions);
        setTotalCount(data.total_count);
      } catch (error) {
        console.error("Error fetching submissions", error);
        toast.error("Failed to fetch submissions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [
    page,
    pageSize,
    filterUnderwritingStatus,
    filterUnderwriter,
    filterInsuredName,
    filterBrokerName,
    sortBy,
    sortOrder,
    navigate,
  ]);

  // Fetch underwriters list
  useEffect(() => {
    const fetchUnderwriters = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await fetch(
          `${apiConfig.baseURL}/api/users/get-underwriters`,
          { headers }
        );
        if (!response.ok) {
          throw new Error("Error fetching underwriters");
        }
        const data = await response.json();
        setUnderwriters(data.underwriters);
      } catch (error) {
        console.error("Error fetching underwriters", error);
        toast.error("Failed to fetch underwriters.");
      }
    };
    fetchUnderwriters();
  }, []);

  // Fetch submission statuses list
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await fetch(
          `${apiConfig.baseURL}/api/submission-details/submission-statuses-list`,
          { headers }
        );
        if (!response.ok) {
          throw new Error("Error fetching submission statuses");
        }
        const data = await response.json();
        setStatuses(data);
      } catch (error) {
        console.error("Error fetching submission statuses", error);
        toast.error("Failed to fetch submission statuses.");
      }
    };
    fetchStatuses();
  }, []);

  // Update submission completion/status every 2 seconds.
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const updatedSubmissions = await Promise.all(
        submissions.map(async (submission) => {
          if (
            submission.status !== "completed" &&
            submission.status !== "failed"
          ) {
            const result = await checkCompletion(submission._id);
            let updatedSubmission = { ...submission };
            let changed = false;

            if (
              result.insured_name !== submission.insured_name &&
              result.insured_name !== "..."
            ) {
              updatedSubmission.insured_name = result.insured_name;
              changed = true;
            }
            if (
              result.broker_name !== submission.broker_name &&
              result.broker_name !== "..."
            ) {
              updatedSubmission.broker_name = result.broker_name;
              changed = true;
            }
            if (result.completeness !== submission.completion) {
              updatedSubmission.completion = result.completeness;
              changed = true;
            }
            if (result.status !== submission.status) {
              updatedSubmission.status = result.status;
              changed = true;
            }
            if (result.property_address !== submission.property_address) {
              updatedSubmission.property_address = result.property_address;
              changed = true;
            }
            return changed ? updatedSubmission : submission;
          }
          return submission;
        })
      );

      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((prevSubmission) => {
          const updatedSubmission = updatedSubmissions.find(
            (s) => s._id === prevSubmission._id
          );
          return updatedSubmission ? updatedSubmission : prevSubmission;
        })
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [submissions]);

  // Re-initialize Bootstrap tooltips whenever submissions change.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.bootstrap) {
        const tooltipTriggerList = Array.from(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.forEach((el) => {
          if (el._tooltip) {
            el._tooltip.dispose();
          }
          el._tooltip = new window.bootstrap.Tooltip(el);
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [submissions]);

  const checkCompletion = async (submissionId) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-details/submission-completeness/${submissionId}`,
        { headers }
      );

      if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
        return;
      } else if (!response.ok) {
        throw new Error(`Error fetching completeness: ${response.statusText}`);
      }
      const data = await response.json();
      return {
        completeness: data.completeness,
        status: data.status,
        insured_name: data.insured_name,
        broker_name: data.broker_name,
        property_address: data.property_address,
      };
    } catch (error) {
      console.error("Error fetching submission completeness", error);
      toast.error(`Error fetching completeness for submission ${submissionId}`);
      return {
        completeness: 0,
        status: "unknown",
        insured_name: "N/A",
        broker_name: "N/A",
        property_address: "N/A",
      };
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat(undefined, options).format(date);
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case "initiated":
        return (
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            Pending
          </Badge>
        );
      case "file_loading":
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-info" />
            File Scanning
          </Badge>
        );
      case "processing":
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-info" />
            Data Extraction
          </Badge>
        );
      case "completed":
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-success" />
            Completed
          </Badge>
        );
      case "failed":
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-danger" />
            Failed
          </Badge>
        );
      default:
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-default" />
            Unknown
          </Badge>
        );
    }
  };

  const getProgressBarColor = (percentage) => {
    if (percentage <= 30) {
      return "bg-danger";
    } else if (percentage > 30 && percentage <= 60) {
      return "bg-warning";
    } else if (percentage > 60 && percentage < 100) {
      return "bg-info";
    } else {
      return "bg-success";
    }
  };

  const getCompletionPercentage = (submission) => {
    if (submission.status === "initiated") {
      return 0;
    } else if (submission.status === "completed") {
      return 100;
    } else if (submission.completion !== undefined) {
      return Math.round(submission.completion);
    } else {
      return 0;
    }
  };

  const toggleModal = (submission) => {
    if (submission) {
      setSelectedSubmission(submission);
    } else {
      setSelectedSubmission(null);
    }
    setModal(!modal);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-details/submission/${selectedSubmission._id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          method: "DELETE",
        }
      );

      if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (!response.ok) {
        throw new Error(`Error deleting submission: ${response.statusText}`);
      }
      setSubmissions((prev) =>
        prev.filter((s) => s._id !== selectedSubmission._id)
      );
      setSelectedSubmission(null);
      setModal(false);
      toast.success("Submission deleted successfully!");
    } catch (error) {
      console.error("Error deleting submission", error);
      toast.error(
        `Failed to delete submission: ${selectedSubmission?.insured_name}. Please try again.`
      );
    }
  };

  const handleOpenDetails = (submissionId) => {
    navigate(`/main/submission/${submissionId}`);
  };

  const handleUpdateUnderwriter = async (submissionId, newUnderwriter) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-details/update-underwriter`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            submission_id: submissionId,
            new_underwriter: newUnderwriter,
          }),
        }
      );
      if (!response.ok) throw new Error("Error updating underwriter");
      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission._id === submissionId
            ? { ...submission, underwriter: newUnderwriter }
            : submission
        )
      );
      toast.success("Underwriter updated successfully");
    } catch (error) {
      console.error("Error updating underwriter", error);
      toast.error("Failed to update underwriter");
    }
  };

  const handleUpdateUnderwritingStatus = async (submissionId, newStatus) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-details/update-underwriting-status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            submission_id: submissionId,
            new_status: newStatus,
          }),
        }
      );
      if (!response.ok) throw new Error("Error updating underwriting status");
      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission._id === submissionId
            ? { ...submission, underwriting_status: newStatus }
            : submission
        )
      );
      toast.success("Underwriting status updated successfully");
    } catch (error) {
      console.error("Error updating underwriting status", error);
      toast.error("Failed to update underwriting status");
    }
  };

  // Handle sorting: clicking a header toggles or sets sort parameters.
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 1 ? -1 : 1);
    } else {
      setSortBy(field);
      setSortOrder(1);
    }
    // Reset to first page on sort change
    setPage(1);
  };

  // Render filter form
  const renderFilters = () => (
    <Form inline className="mb-3">
      <FormGroup className="mr-3">
        <Label for="filterUnderwritingStatus" className="mr-2">
          Underwriting Status:
        </Label>
        <Input
          type="select"
          id="filterUnderwritingStatus"
          value={filterUnderwritingStatus}
          onChange={(e) => {
            setFilterUnderwritingStatus(e.target.value);
            setPage(1);
          }}
        >
          <option value="">All</option>
          {Object.entries(statuses).map(([key, displayValue]) => (
            <option key={key} value={key}>
              {displayValue}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup className="mr-3">
        <Label for="filterUnderwriter" className="mr-2">
          Underwriter:
        </Label>
        <Input
          type="select"
          id="filterUnderwriter"
          value={filterUnderwriter}
          onChange={(e) => {
            setFilterUnderwriter(e.target.value);
            setPage(1);
          }}
        >
          <option value="">All</option>
          {underwriters.map((u) => (
            <option key={u.email} value={u.email}>
              {u.first_name} {u.last_name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup className="mr-3">
        <Label for="filterInsuredName" className="mr-2">
          Insured Name:
        </Label>
        <Input
          type="text"
          id="filterInsuredName"
          value={filterInsuredName}
          onChange={(e) => {
            setFilterInsuredName(e.target.value);
            setPage(1);
          }}
          placeholder="Search..."
        />
      </FormGroup>
      <FormGroup className="mr-3">
        <Label for="filterBrokerName" className="mr-2">
          Broker Name:
        </Label>
        <Input
          type="text"
          id="filterBrokerName"
          value={filterBrokerName}
          onChange={(e) => {
            setFilterBrokerName(e.target.value);
            setPage(1);
          }}
          placeholder="Search..."
        />
      </FormGroup>
    </Form>
  );

  // Compute the total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">
                    <i className="fa-solid fa-list"></i> &nbsp; &nbsp; All
                    Submissions
                  </h3>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                  >
                    {filtersVisible ? "Hide Filters" : "Show Filters"}
                  </Button>
                </div>
              </CardHeader>
              {filtersVisible && <div className="px-3">{renderFilters()}</div>}
              {loading ? (
                <div className="text-center my-5">
                  <Spinner color="primary" />
                  <p>Loading submissions...</p>
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "2px" }}></th>
                      <th
                        onClick={() => handleSort("created")}
                        style={{ cursor: "pointer" }}
                      >
                        Date{" "}
                        {sortBy === "created" && (sortOrder === 1 ? "▲" : "▼")}
                      </th>
                      <th
                        onClick={() => handleSort("insured_name")}
                        style={{ cursor: "pointer" }}
                      >
                        Insured Name{" "}
                        {sortBy === "insured_name" &&
                          (sortOrder === 1 ? "▲" : "▼")}
                      </th>
                      <th
                        onClick={() => handleSort("broker_name")}
                        style={{ cursor: "pointer" }}
                      >
                        Broker Name{" "}
                        {sortBy === "broker_name" &&
                          (sortOrder === 1 ? "▲" : "▼")}
                      </th>
                      <th>Underwriter</th>
                      <th
                        onClick={() => handleSort("uploaded_by")}
                        style={{ cursor: "pointer" }}
                      >
                        Uploaded By{" "}
                        {sortBy === "uploaded_by" &&
                          (sortOrder === 1 ? "▲" : "▼")}
                      </th>
                      <th>Processing</th>
                      <th>Underwriting Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {submissions.map((submission) => {
                      const completionPercentage =
                        getCompletionPercentage(submission);
                      const insuredName = submission.insured_name || "/";
                      const brokerName = submission.broker_name || "/";
                      const insuredSummary = submission.insured_summary || "";
                      const brokerSummary = submission.broker_summary || "";
                      const uploader = underwriters.find(
                        (u) => u.email === submission.uploaded_by
                      ) || { email: submission.uploaded_by };

                      return (
                        <tr key={submission._id}>
                          <td
                            style={{
                              width: "2px",
                              padding: "0",
                              backgroundColor: getIndicatorColor(submission),
                            }}
                          ></td>
                          <td>{formatDate(submission.created)}</td>
                          <td
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            {...(insuredSummary && {
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "top",
                              title: insuredSummary,
                            })}
                          >
                            {insuredName}
                          </td>
                          <td
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            {...(brokerSummary && {
                              "data-bs-toggle": "tooltip",
                              "data-bs-placement": "top",
                              title: brokerSummary,
                            })}
                          >
                            {brokerName}
                          </td>
                          <td>
                            <Input
                              type="select"
                              value={
                                underwriters.some(
                                  (u) => u.email === submission.underwriter
                                )
                                  ? submission.underwriter
                                  : ""
                              }
                              onChange={(e) =>
                                handleUpdateUnderwriter(
                                  submission._id,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Underwriter</option>
                              {underwriters.map((u) => (
                                <option key={u.email} value={u.email}>
                                  {u.first_name} {u.last_name}
                                </option>
                              ))}
                            </Input>
                          </td>
                          <td>
                            {uploader.first_name
                              ? `${uploader.first_name} ${uploader.last_name}`
                              : uploader.email}
                          </td>
                          <td>
                            <div>
                              {getStatusBadge(submission.status)}
                              {submission.status !== "completed" &&
                                submission.status !== "failed" && (
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="flex-grow-1">
                                      <Progress
                                        max="100"
                                        value={completionPercentage}
                                        barClassName={getProgressBarColor(
                                          completionPercentage
                                        )}
                                      />
                                    </div>
                                    <span className="ml-3">
                                      {completionPercentage}%
                                    </span>
                                  </div>
                                )}
                            </div>
                          </td>
                          <td>
                            <Input
                              type="select"
                              value={
                                Object.keys(statuses).includes(
                                  submission.underwriting_status
                                )
                                  ? submission.underwriting_status
                                  : ""
                              }
                              onChange={(e) =>
                                handleUpdateUnderwritingStatus(
                                  submission._id,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Status</option>
                              {Object.entries(statuses).map(
                                ([key, displayValue]) => (
                                  <option key={key} value={key}>
                                    {displayValue}
                                  </option>
                                )
                              )}
                            </Input>
                          </td>
                          <td className="text-center">
                            {(submission.status === "completed" ||
                              submission.status === "failed") && (
                              <Button
                                color="info"
                                outline
                                type="button"
                                onClick={() =>
                                  handleOpenDetails(submission._id)
                                }
                              >
                                <i className="fa-solid fa-eye"></i>
                              </Button>
                            )}{" "}
                            <Button
                              color="danger"
                              outline
                              type="button"
                              onClick={() => toggleModal(submission)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Pagination className="pagination mb-0" listClassName="mb-0">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(page - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                      </PaginationLink>
                    </PaginationItem>
                    {Array.from({ length: totalPages }).map((_, i) => {
                      const pageNum = i + 1;
                      return (
                        <PaginationItem key={pageNum} active={pageNum === page}>
                          <PaginationLink onClick={() => setPage(pageNum)}>
                            {pageNum}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem disabled={page === totalPages}>
                      <PaginationLink onClick={() => setPage(page + 1)}>
                        <i className="fas fa-angle-right" />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                  <Form inline>
                    <FormGroup className="mr-2">
                      <Label for="pageSizeSelect" className="mr-2 mb-0">
                        Items per page:
                      </Label>
                      <Input
                        type="select"
                        id="pageSizeSelect"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the submission:{" "}
          {selectedSubmission?.insured_name}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={() => toggleModal(null)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SubmissionsList;
