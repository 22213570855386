// src/pages/submission-details-page/components/source-modal.js

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import apiConfig from "config";
import "./source-modal.css";

// -- Helpers --
const getExtension = (fileName) => {
  if (!fileName) return "";
  const parts = fileName.split(".");
  return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : "";
};

const isHtmlPreview = (ext) => ["html", "eml", "msg"].includes(ext);
const isPdfPreview = (ext) => ext === "pdf";

// Adjust how many characters to show before "See full"
const SNIPPET_LENGTH = 120;

const SourcePreviewModal = ({ isOpen, toggle, sources, submissionId }) => {
  // Which source is currently active (being previewed on the right)
  const [activeIndex, setActiveIndex] = useState(0);

  // Keep track of which items are expanded (show full chunk_content)
  const [expandedIndices, setExpandedIndices] = useState([]);

  // State for file-based preview
  const [fileBlobUrl, setFileBlobUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  // When we *do not* fetch the file (for non-PDF/HTML types),
  // the user wants to see `chunk_content`. We'll store it
  // here directly for the right panel in those cases.
  const [textPreview, setTextPreview] = useState("");

  // Reset to the first source when sources change
  useEffect(() => {
    if (sources && sources.length > 0) {
      setActiveIndex(0);
      setExpandedIndices([]);
    }
  }, [sources]);

  // Cleanup any existing Blob URLs on unmount
  useEffect(() => {
    return () => {
      if (fileBlobUrl) {
        URL.revokeObjectURL(fileBlobUrl);
      }
    };
  }, [fileBlobUrl]);

  // Whenever the active source changes, we decide whether to fetch a file or just show chunk_content
  useEffect(() => {
    if (!sources || sources.length === 0) return;

    const currentSource = sources[activeIndex];
    if (!currentSource) return;

    const ext = getExtension(currentSource.source_document);

    // Reset previous state
    setFileBlobUrl(null);
    setTextPreview("");
    setLoading(false);

    // If it's PDF or HTML-like, fetch from backend:
    if (isPdfPreview(ext) || isHtmlPreview(ext)) {
      setLoading(true);
      const token = localStorage.getItem("token");
      const url = `${apiConfig.baseURL}/api/submission-details/submission-file/${submissionId}/${currentSource.source_document}`;

      fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch file (Status: ${res.status})`);
          }
          return res.blob();
        })
        .then((blob) => {
          setFileBlobUrl(URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error("Error fetching file:", error);
          // If a file fetch fails, we can simply show chunk_content as fallback:
          setTextPreview(
            currentSource.chunk_content || "No content available."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Otherwise, just display chunk_content directly
      setTextPreview(currentSource.chunk_content || "No content available.");
    }
  }, [activeIndex, sources, submissionId]);

  // Handle "See full" / "See less" toggling for snippet
  const handleToggleExpand = (e, idx) => {
    // Prevent the click from also selecting the chunk
    e.stopPropagation();
    setExpandedIndices((prev) => {
      if (prev.includes(idx)) {
        return prev.filter((i) => i !== idx);
      }
      return [...prev, idx];
    });
  };

  // Render the list of sources on the left
  const renderLeftSidebar = () => {
    return (
      <div style={{ width: "100%" }}>
        {sources.map((source, index) => {
          const docName = source.source_document || "Untitled Document";
          const fullText = source.chunk_content || "";
          const isActive = index === activeIndex;
          const isExpanded = expandedIndices.includes(index);

          // The snippet if not expanded
          const snippet =
            fullText.length > SNIPPET_LENGTH
              ? fullText.slice(0, SNIPPET_LENGTH) + "..."
              : fullText;

          const displayText = isExpanded ? fullText : snippet;

          return (
            <div
              key={index}
              onClick={() => setActiveIndex(index)}
              // Basic "card" styling; highlight if active
              style={{
                border: "1px solid #ccc",
                borderRadius: 4,
                padding: 8,
                marginBottom: 10,
                cursor: "pointer",
                backgroundColor: isActive ? "#e8f4fa" : "#fff",
              }}
            >
              {/* Header: Document name */}
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  marginBottom: 6,
                }}
              >
                {docName}
              </div>

              {/* Body: snippet or full text */}
              <div style={{ fontSize: 12, color: "#333" }}>{displayText}</div>

              {/* "See full"/"See less" link if text is long */}
              {fullText.length > SNIPPET_LENGTH && (
                <div style={{ marginTop: 4, fontSize: 12 }}>
                  <span
                    onClick={(e) => handleToggleExpand(e, index)}
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    {isExpanded ? "See less" : "See full"}
                  </span>
                </div>
              )}

              {/* Footer: Page number */}
              {typeof source.page === "number" && (
                <div
                  style={{
                    fontSize: 11,
                    color: "#777",
                    marginTop: 8,
                    borderTop: "1px solid #eee",
                    paddingTop: 6,
                  }}
                >
                  Page: {source.page + 1}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // Render the file preview in the main content area
  const renderPreview = () => {
    if (!sources || sources.length === 0) return <p>No sources available.</p>;
    const currentSource = sources[activeIndex];
    if (!currentSource) return <p>Please select a source.</p>;

    // Show a spinner if we are loading
    if (loading) return <Spinner />;

    const ext = getExtension(currentSource.source_document);

    // If extension is PDF or HTML-like:
    if (isPdfPreview(ext) || isHtmlPreview(ext)) {
      if (fileBlobUrl) {
        // PDF
        if (isPdfPreview(ext)) {
          const pageParam =
            typeof currentSource.page === "number"
              ? `#page=${currentSource.page + 1}`
              : "";
          return (
            <iframe
              title="PDF Preview"
              src={`${fileBlobUrl}${pageParam}`}
              type="application/pdf"
              style={{ width: "100%", height: "500px", border: "none" }}
            >
              <p>
                This browser does not support embedded PDFs.{" "}
                <a href={fileBlobUrl} target="_blank" rel="noreferrer">
                  Click here to download the PDF
                </a>
              </p>
            </iframe>
          );
        }
        // HTML or EML or MSG
        return (
          <iframe
            title="HTML Preview"
            src={fileBlobUrl}
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        );
      } else {
        // if fileBlobUrl is null (fetch error?), fallback to chunk_content
        return (
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <pre>{textPreview}</pre>
          </div>
        );
      }
    }

    // For everything else, show chunk_content only
    return (
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <pre>{textPreview}</pre>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="custom-modal">
      <ModalHeader toggle={toggle}>Source Preview</ModalHeader>
      <ModalBody>
        {sources && sources.length > 0 ? (
          <Row>
            {/* Left side: each source in its own "row" */}
            <Col sm="3" style={{ maxHeight: "500px", overflowY: "auto" }}>
              {renderLeftSidebar()}
            </Col>

            {/* Right side: file preview or chunk_content */}
            <Col sm="9">{renderPreview()}</Col>
          </Row>
        ) : (
          <p>No sources available</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SourcePreviewModal;
