import React, { useState } from "react";
// react-flagkit is a default export
import FlagIcon from "react-flagkit";
import { Card, CardBody, CardHeader, Button, Row, Col } from "reactstrap";
import "./../submission-details-page.css";

// Helper: Return an icon class for NAT CAT sublimits
const getNatCatIconClass = (value) => {
  const lowerVal = value.toLowerCase();
  if (lowerVal.includes("flood")) return "fas fa-water"; // Flood icon
  if (lowerVal.includes("earthquake")) return "fas fa-house-damage"; // Earthquake icon
  if (lowerVal.includes("windstorm") || lowerVal.includes("wind"))
    return "fas fa-wind";
  if (lowerVal.includes("bushfire") || lowerVal.includes("fire"))
    return "fas fa-fire";
  return "fas fa-globe"; // default fallback icon
};

// Helper: Return an icon class for deductibles
const getDeductibleIconClass = (value) => {
  const lowerVal = value.toLowerCase();
  if (lowerVal.includes("aop")) return "fas fa-shield-alt";
  if (lowerVal.includes("flood")) return "fas fa-water";
  if (lowerVal.includes("earthquake")) return "fas fa-house-damage";
  if (lowerVal.includes("windstorm") || lowerVal.includes("wind"))
    return "fas fa-wind";
  if (lowerVal.includes("bushfire") || lowerVal.includes("fire"))
    return "fas fa-fire";
  return "fas fa-coins"; // default fallback icon
};

const renderNatCatCard = ({
  title,
  sublimits,
  colWidth,
  sublimitsSources,
  openModalWithSources,
}) => {
  return (
    <Col md={colWidth} className="mb-3">
      <Card className="modern-card h-100">
        <CardBody className="d-flex flex-column align-items-start">
          <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
            <CardHeader className="text-uppercase card-title mb-0">
              {title}
            </CardHeader>
            {/* EXISTING ICON, now clickable */}
            <div
              className="icon icon-shape text-white rounded-circle modern-icon bg-danger"
              style={{ cursor: "pointer" }}
              onClick={() => openModalWithSources(sublimitsSources)}
              title="View Sources"
            >
              <i className="fas fa-exclamation-triangle" />
            </div>
          </div>
          <div className="modern-text flex-grow-1">
            {sublimits && sublimits.length > 0 ? (
              <ul className="list-unstyled">
                {sublimits.map((value, index) => {
                  const iconClass = getNatCatIconClass(value);
                  return (
                    <li
                      key={index}
                      className="modern-list-item d-flex justify-content-between align-items-center"
                      style={{
                        borderBottom: "1px solid transparent",
                        padding: "8px 0",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        style={{ marginRight: "1rem" }}
                      />
                      <i className={iconClass} style={{ fontSize: "32px" }} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const renderDeductiblesCard = ({
  title,
  deductibles,
  colWidth,
  deductiblesSources,
  openModalWithSources,
}) => {
  return (
    <Col md={colWidth} className="mb-3">
      <Card className="modern-card h-100">
        <CardBody className="d-flex flex-column align-items-start">
          <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
            <CardHeader className="text-uppercase card-title mb-0">
              {title}
            </CardHeader>
            {/* EXISTING ICON, now clickable */}
            <div
              className="icon icon-shape text-white rounded-circle modern-icon bg-warning"
              style={{ cursor: "pointer" }}
              onClick={() => openModalWithSources(deductiblesSources)}
              title="View Sources"
            >
              <i className="fas fa-exclamation-circle" />
            </div>
          </div>
          <div className="modern-text flex-grow-1">
            {deductibles && deductibles.length > 0 ? (
              <ul className="list-unstyled">
                {deductibles.map((value, index) => {
                  const iconClass = getDeductibleIconClass(value);
                  return (
                    <li
                      key={index}
                      className="modern-list-item d-flex justify-content-between align-items-center"
                      style={{
                        borderBottom: "1px solid transparent",
                        padding: "8px 0",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        style={{ marginRight: "1rem" }}
                      />
                      <i className={iconClass} style={{ fontSize: "32px" }} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const renderLimitsAndExcessCard = ({
  limits,
  excess,
  colWidth = 4,
  title = "Requested Limits & Excess",
  limitsSources,
  excessSources,
  openModalWithSources,
}) => {
  // If you want one click for both sources:
  const combinedSources = [...(limitsSources || []), ...(excessSources || [])];

  return (
    <Col md={colWidth} className="mb-3">
      <Card className="modern-card h-100">
        <CardBody className="d-flex flex-column align-items-start">
          <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
            <CardHeader className="text-uppercase card-title mb-0">
              {title}
            </CardHeader>
            {/* EXISTING ICON, now clickable */}
            <div
              className="icon icon-shape text-white rounded-circle modern-icon bg-primary"
              style={{ cursor: "pointer" }}
              onClick={() => openModalWithSources(combinedSources)}
              title="View Sources"
            >
              <i className="fas fa-layer-group" />
            </div>
          </div>
          <div className="modern-text mb-3 mt-2 w-100">
            <h5 className="mb-2">Limits</h5>
            {limits && limits.length > 0 ? (
              <ul className="list-unstyled">
                {limits.map((value, index) => (
                  <li
                    key={index}
                    className="modern-list-item"
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                ))}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
          <div className="modern-text w-100">
            <h5 className="mb-2">Excess</h5>
            {excess && excess.length > 0 ? (
              <ul className="list-unstyled">
                {excess.map((value, index) => (
                  <li
                    key={index}
                    className="modern-list-item"
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                ))}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

///////////////////////////////////////////////////////////////////
// Main PerilDetails Component
///////////////////////////////////////////////////////////////////
const PerilDetails = ({
  perils,
  country_name,
  country_code,
  broker_name,
  broker_company,
  country_name_sources,
  broker_name_sources,
  openModalWithSources, // <-- pass from SubmissionDetails
}) => {
  const [expandedPerils, setExpandedPerils] = useState({});

  const togglePerilSummary = (perilName) => {
    setExpandedPerils((prevState) => ({
      ...prevState,
      [perilName]: !prevState[perilName],
    }));
  };

  // Render the second row (which includes the "Country of Insured" panel)
  const renderSecondRow = (details) => {
    const finalCountryName = country_name || "";
    const finalCountryCode = country_code ? country_code.toUpperCase() : "";

    return (
      <Row className="mb-3">
        {/* 2.1 Country of Insured */}
        <Col md="2" className="mb-3">
          <Card className="modern-card h-100">
            <CardBody className="d-flex flex-column align-items-start">
              <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
                <CardHeader className="text-uppercase card-title mb-0">
                  Country of Insured
                </CardHeader>
                {/* EXISTING ICON, now clickable */}
                <div
                  className="icon icon-shape text-white rounded-circle modern-icon bg-info"
                  style={{ cursor: "pointer" }}
                  onClick={() => openModalWithSources(country_name_sources)}
                  title="View Sources"
                >
                  <i className="fas fa-flag" />
                </div>
              </div>
              <div className="modern-text mt-2" style={{ width: "100%" }}>
                {finalCountryCode || finalCountryName ? (
                  <div style={{ textAlign: "center" }}>
                    <FlagIcon
                      country={finalCountryCode}
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                    <div style={{ marginTop: "0.5rem" }}>
                      {finalCountryName}
                    </div>
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* 2.2 Broker Panel: Broker Name + Broker Company */}
        <Col md="2" className="mb-3">
          <Card className="modern-card h-100">
            <CardBody className="d-flex flex-column align-items-start">
              <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
                <CardHeader className="text-uppercase card-title mb-0">
                  Broker
                </CardHeader>
                {/* EXISTING ICON, now clickable */}
                <div
                  className="icon icon-shape text-white rounded-circle modern-icon bg-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => openModalWithSources(broker_name_sources)}
                  title="View Sources"
                >
                  <i className="fas fa-user-tie" />
                </div>
              </div>
              <div className="modern-text mt-2">
                {broker_name || broker_company ? (
                  <>
                    <div className="d-flex mb-2">
                      <strong style={{ width: "80px" }}>Name:</strong>
                      <span>{broker_name || "N/A"}</span>
                    </div>
                    <div className="d-flex">
                      <strong style={{ width: "80px" }}>Company:</strong>
                      <span>{broker_company || "N/A"}</span>
                    </div>
                  </>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* 2.3 Target Premium */}
        <Col md="2" className="mb-3">
          <Card className="modern-card h-100">
            <CardBody className="d-flex flex-column align-items-start">
              <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
                <CardHeader className="text-uppercase card-title mb-0">
                  Target Premium
                </CardHeader>
                {/* EXISTING ICON, now clickable */}
                <div
                  className="icon icon-shape text-white rounded-circle modern-icon bg-success"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(details.peril_target_premium_sources)
                  }
                  title="View Sources"
                >
                  <i className="fas fa-tags" />
                </div>
              </div>
              <div className="modern-text mt-2">
                {details.peril_target_premium?.length > 0 ? (
                  <ul className="list-unstyled">
                    {details.peril_target_premium.map((val, idx) => (
                      <li
                        key={idx}
                        className="modern-list-item"
                        dangerouslySetInnerHTML={{ __html: val }}
                      />
                    ))}
                  </ul>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* 2.4 Target Rate */}
        <Col md="2" className="mb-3">
          <Card className="modern-card h-100">
            <CardBody className="d-flex flex-column align-items-start">
              <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
                <CardHeader className="text-uppercase card-title mb-0">
                  Target Rate
                </CardHeader>
                {/* EXISTING ICON, now clickable */}
                <div
                  className="icon icon-shape text-white rounded-circle modern-icon bg-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(details.peril_target_rate_sources)
                  }
                  title="View Sources"
                >
                  <i className="fas fa-percentage" />
                </div>
              </div>
              <div className="modern-text mt-2">
                {details.peril_target_rate?.length > 0 ? (
                  <ul className="list-unstyled">
                    {details.peril_target_rate.map((val, idx) => (
                      <li
                        key={idx}
                        className="modern-list-item"
                        dangerouslySetInnerHTML={{ __html: val }}
                      />
                    ))}
                  </ul>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* 2.5 Deductions */}
        <Col md="4" className="mb-3">
          <Card className="modern-card h-100">
            <CardBody className="d-flex flex-column align-items-start">
              <div className="card-header-wrapper d-flex justify-content-between align-items-center w-100">
                <CardHeader className="text-uppercase card-title mb-0">
                  Deductions
                </CardHeader>
                {/* EXISTING ICON, now clickable */}
                <div
                  className="icon icon-shape text-white rounded-circle modern-icon bg-dark"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openModalWithSources(details.peril_deductions_sources)
                  }
                  title="View Sources"
                >
                  <i className="fas fa-minus-circle" />
                </div>
              </div>
              <div className="modern-text mt-2">
                {details.peril_deductions?.length > 0 ? (
                  <ul className="list-unstyled">
                    {details.peril_deductions.map((val, idx) => (
                      <li
                        key={idx}
                        className="modern-list-item"
                        dangerouslySetInnerHTML={{ __html: val }}
                      />
                    ))}
                  </ul>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  // First row (3 panels): Limits & Excess, NAT CAT Sublimits, Deductibles
  const renderPerilDetails = (details) => {
    return (
      <>
        <Row className="d-flex justify-content-between">
          {renderLimitsAndExcessCard({
            limits: details.peril_limits,
            excess: details.peril_excess,
            colWidth: 4,
            title: "Requested Limits & Excess",
            limitsSources: details.peril_limits_sources,
            excessSources: details.peril_excess_sources,
            openModalWithSources,
          })}

          {renderNatCatCard({
            title: "Key NAT CAT Sublimits",
            sublimits: details.peril_natural_catastrophe_sublimits,
            sublimitsSources:
              details.peril_natural_catastrophe_sublimits_sources,
            colWidth: 4,
            openModalWithSources,
          })}

          {renderDeductiblesCard({
            title: "Deductibles",
            deductibles: details.peril_deductibles,
            deductiblesSources: details.peril_deductibles_sources,
            colWidth: 4,
            openModalWithSources,
          })}
        </Row>
        {renderSecondRow(details)}
      </>
    );
  };

  return (
    <div className="mt-5">
      <Row>
        {perils.map((peril) => (
          <Col xl="12" key={peril.peril_name} className="mb-4">
            <Card className="modern-peril-panel">
              <CardHeader className="bg-white border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">{peril.peril_name}</h3>
                <Button
                  color="primary"
                  onClick={() => togglePerilSummary(peril.peril_name)}
                  size="sm"
                >
                  {expandedPerils[peril.peril_name]
                    ? "Hide Summary"
                    : "Read Summary"}
                </Button>
              </CardHeader>

              <CardBody>
                {expandedPerils[peril.peril_name] && (
                  <div
                    className="peril-summary mb-4 modern-summary"
                    dangerouslySetInnerHTML={{ __html: peril.peril_summary }}
                  />
                )}
                {renderPerilDetails(peril.peril_details)}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PerilDetails;
