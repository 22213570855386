import React, { useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Button,
  Spinner,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./new-submission-page.css";
import apiConfig from "config";
import MainHeader from "components/Headers/Header.js";

const NewSubmissionPage = () => {
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("idle"); // 'idle', 'loading', 'success', 'error'
  const [submissionId, setSubmissionId] = useState("");
  const navigate = useNavigate(); // UseNavigate hook for navigation

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.warn("Please select at least one file.");
      return;
    }

    setUploadStatus("loading");

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-processing/upload-submission-files`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
        return;
      } else if (!response.ok) {
        throw new Error("File upload failed.");
      }

      const data = await response.json();
      setSubmissionId(data._id);

      setUploadStatus("success");
      toast.success("Files uploaded successfully!");

      // Automatically start processing after successful upload
      handleProcess(data._id);
    } catch (error) {
      setUploadStatus("error");
      toast.error("Error uploading files.");
      console.error(error);
    }
  };

  const handleProcess = async (id) => {
    if (!id) {
      toast.warn("No submission ID found.");
      return;
    }

    toast.info("Starting to process files...");

    try {
      const token = localStorage.getItem("token");
      // Call the backend to trigger the background processing
      const response = await fetch(
        `${apiConfig.baseURL}/api/submission-processing/extract-submission-details/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
        return;
      } else if (!response.ok) {
        throw new Error("Failed to start extracting information.");
      }

      toast.success("File extraction started successfully!");

      // Automatically navigate to another page after processing is triggered
      navigate("/main/submissions");
    } catch (error) {
      toast.error("Error starting extraction: " + error.message);
      console.error(error);
    }
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop });

  const filesList = (
    <ListGroup>
      {acceptedFiles.map((file) => (
        <ListGroupItem key={file.path}>
          {file.path} - {file.size} bytes
        </ListGroupItem>
      ))}
    </ListGroup>
  );

  return (
    <>
      <MainHeader showCards={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">
                  <i className="fa-solid fa-file-circle-plus"></i> &nbsp; &nbsp;
                  Start New Submission Processing
                </h3>
              </CardHeader>
              <CardBody>
                {uploadStatus !== "success" && (
                  <div>
                    <div
                      {...getRootProps({
                        className: `mt-2 dropzone col-lg-8 offset-lg-2 ${
                          isDragActive ? "dropzone-active" : ""
                        }`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <>
                        <i className="fa-solid fa-file-arrow-up dropzone-icon"></i>
                        {isDragActive ? (
                          <p className="mt-2">Drop the files here...</p>
                        ) : (
                          <p className="mt-2">
                            Drag &amp; drop submission file here, or click to
                            select a file
                          </p>
                        )}
                      </>
                    </div>
                    {acceptedFiles.length > 0 && (
                      <aside className="mt-3">
                        <h6>Files:</h6>
                        {filesList}
                      </aside>
                    )}
                    <Button
                      type="button"
                      color="primary"
                      block
                      className="mt-4 col-lg-2 offset-lg-5"
                      onClick={handleSubmit}
                    >
                      Start Upload and Processing
                    </Button>
                  </div>
                )}
                {uploadStatus === "loading" && (
                  <div className="loading-overlay">
                    <div className="text-center">
                      <Spinner color="primary" className="large-spinner" />
                      <p className="mt-2 text-white">Uploading files...</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NewSubmissionPage;
